import React, { memo, useEffect, useMemo, useState } from "react";
import { Table, Empty, message, Input } from "antd";
import { useMsal } from "@azure/msal-react";
import axios from "axios";
import { CopyOutlined } from "@ant-design/icons";
import { getAccessToken } from "config/token";
import { explorerUrl, hostnameUrl } from "http/index";
import ListPageHeader from "components/ListPageHeader";
import { ColumnsType } from "antd/es/table";
import { IPond } from "./types";
import { NEXUS_API_BASE_URL } from "const";

interface PondsTableRow {
  display_name: string;
}

const Ponds: React.FC = () => {
  const msalInstance = useMsal();
  const [selRegion, setSelRegion] = useState("eu-central-1");
  const [domains, setDomains] = useState<Array<{ display_name: string }>>([]);
  const [loader, setLoader] = useState(false);
  const [ponds, setPonds] = useState<Array<IPond>>([]);
  const [search, setSearch] = useState(""); // Search input state

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);
      const token = await getAccessToken(msalInstance);

      const bearerToken = "Bearer " + (token == undefined ? "" : token.token);
      const requestURL = NEXUS_API_BASE_URL + "/nexus/get-domains";


      const config = {
        headers: { "Authorization": bearerToken },
      };

      axios
        .get(requestURL, config)
        .then((res) => {
          if (res.data) {
            // Store the complete list of domains
            setDomains(res.data);
            setPonds(res.data);
            setLoader(false);
          }
        })
        .catch(() => {
          setLoader(false);
        });
    };

    fetchData();
  }, [msalInstance]);

  // Filter ponds based on search query
  const searchedPonds = search
    ? ponds.filter((item) =>
        item.display_name.toLowerCase().includes(search.toLowerCase()),
      )
    : ponds; // If search is empty, show the full list

  const columns: ColumnsType<PondsTableRow> = useMemo(() => [
    {
      title: "Display Name",
      dataIndex: "display_name",
      key: "display_name",
    },
  ], []);

  const handleCopyJSON = (name: string) => {
    navigator.clipboard.writeText(name);
    message.success({
      content: "Data Domain name is copied",
      duration: 1,
    });
  };

  const PONDS_TABLE_COLUMNS: () => ColumnsType<PondsTableRow> = () => [
    {
      title: "Data Domain",
      dataIndex: "display_name", 
      key: "display_name",       
      render: (value) => (
        <div className="name">
          <a href={`${hostnameUrl}${explorerUrl}?domain_name=${value}`}>
            {value}
          </a>
        </div>
      ),
    },
  ];

  return (
    <div className="ponds-list">
      <ListPageHeader setSearchItems={setSearch} />
      
      <div style={{ marginBottom: "16px" }}>
        <Input
          placeholder="Search by Display Name"
          className="Input-search"
          value={search}
          onChange={(e) => setSearch(e.target.value)} 
        />
      </div>
      
      <div className="ponds-table" style={{ marginLeft: "25px" }}>
        <Table
          dataSource={searchedPonds} 
          columns={PONDS_TABLE_COLUMNS()}
          locale={{
            emptyText: <Empty description="No Data Domains Found" />,
          }}
          loading={loader}
          pagination={{
            pageSize: 10,
            pageSizeOptions: [5, 10],
          }}
          rowKey="display_name" 
        />
      </div>
    </div>
  );
};

export default memo(Ponds);
